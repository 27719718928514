module.exports = [{
      plugin: require('../node_modules/.pnpm/@chakra-ui+gatsby-plugin@3.1.3_@chakra-ui+react@2.10.6_@emotion+react@11.14.0_@types+re_784567c0412e56fff6d10703df1644a0/node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__react-do_d1fd1034d28f8bb2e9d8ebca4aadfc45/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#F5F5F5","display":"standalone","icon":"src/images/icon.png","name":"Pamela Keravuori Art","short_name":"PK.Art","start_url":"/","theme_color":"#F5F5F5","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b17d7cee86a90e1f4c7856171f1c0384"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-ackee-tracker@4.1.4_gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__reac_ad5b1a370510b85b5316d0f15d89071d/node_modules/gatsby-plugin-ackee-tracker/gatsby-browser.js'),
      options: {"plugins":[],"detailed":true,"domainId":"50405dd3-b8a6-4937-a36d-b98bbe9c847c","server":"https://info.pamelakeravuori.art","ignoreLocalhost":true,"ignoreOwnVisits":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18.3.1_react@18.3.1__react@1_f1cee5fe5a172c1fc5062e763cc04bc7/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
